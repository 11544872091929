.active {
  border-bottom: 3px solid #5E2212;
  color: #5E2212;
  border-radius: 5px;
}
.logo{
    position: relative;
}

.navbar_bg{
  /* background-image: linear-gradient(red, yellow); */
  background-image: linear-gradient(to right, rgb(255, 209, 59),rgb(253, 250, 144)) !important;
}

.nav_logo {
  width: 220px;
  height: 80px;
  /* -webkit-clip-path: polygon(0 0, 0 40%, 100% 40%, 100% 25%, 50% 0) !important; */
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 45%, 85% 0) !important;
  display: flex;
  align-items: center;
  /* justify-content: ; */
  padding-left: 10px;
}

.line_drow {
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: rgb(255, 255, 255);
  top: 30px;
  right: -30px;
  rotate: 50deg;
  z-index: 20;
}
