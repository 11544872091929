*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #8d8d8d; */
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
